import React from "react";

import { useAccountStatus } from "hooks/useAccountStatus";

interface Props {
	isMentorsOnly: boolean;
	isMenteesOnly: boolean;
}

const RestrictedSection: React.FunctionComponent<Props> = ({
	children,
	isMentorsOnly,
	isMenteesOnly
}) => {
	const { isMentor } = useAccountStatus();

	if (isMentor === undefined) {
		return null;
	}

	const isMentee = !isMentor;
	if (isMenteesOnly && !isMentee) {
		return null;
	}

	if (isMentorsOnly && !isMentor) {
		return null;
	}

	return <>{children}</>;
};

export default RestrictedSection;
